.errorMessage {
  color: orange;
}

.successMessage {
  color: white;
}

.successMessagePopup {
  color: #ff8135;
}
